<template>
  <div class="nav-container">
    <div class="nav-item title">帮助中心</div>
    <div
      v-for="item in helpCenterList"
      :key="item.id"
      class="list-items">
      <div
        class="nav-item root-item"
        :class="isRootActive(item.id)"
        :style="getRootStyle(item.id)"
        @click="handleChangeRootComponent(item.id)">
        {{ item.content }}
      </div>
      <div
        v-for="child in getChild(item)"
        :key="child.id"
        class="child-item"
        :class="isChildActive(child.id)"
        @click="handleChangeChildComponent(child.id)">
        {{ child.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { helpCenterList } from '@/constants/helpCenter'
import { has, lowerFirst, find } from 'lodash'
import arrowDown from '@/assets/arrow-down.png'
import arrowUp from '@/assets/arrow-up.png'

export default {
  props: {
    selected: {
      type: String
    }
  },
  data () {
    return {
      helpCenterList: helpCenterList,
      selRoot: 'FAQ',
      selChild: ''
    }
  },
  created () {
    if (this.selected) {
      this.selRoot = this.selected
    }
  },
  methods: {
    getChild (item) {
      if (
        has(item, 'children') &&
        this.selRoot === item.id
      ) {
        return item.children
      }
      return null
    },
    handleChangeRootComponent (id) {
      const clickRoot = this.findRootId(id)
      this.emitHandleChangeComponent(id)
      // if (clickRoot === this.selRoot) {
      //   this.selRoot = ''
      // } else {
      this.selRoot = clickRoot
      // }
    },
    handleChangeChildComponent (id) {
      this.emitHandleChangeComponent(id)
      this.selChild = id
    },
    emitHandleChangeComponent (id) {
      if (!this.isIgnore(id)) {
        this.$emit('handleChangeComponent', id)
      }
    },
    findRootId (id) {
      const rootSearch = find(this.helpCenterList, (obj) => {
        return obj.id === id
      })
      if (rootSearch) {
        return id
      } else {
        const root = this.helpCenterList.filter(obj => {
          if (has(obj, 'children')) {
            return obj.children.filter(child => {
              return child.id === id
            })
          }
          return false
        })[0]
        return root.id
      }
    },
    isIgnore (str) {
      return lowerFirst(str) === str
    },
    getRootStyle (id) {
      const item = this.helpCenterList.filter(i => {
        return i.id === id
      })[0]
      const style = {
        'background-repeat': 'no-repeat',
        'background-position': '88% 50%'
      }
      if (
        this.hasChildren(item)
      ) {
        if (this.isSelRoot(id)) {
          style['background-image'] = 'url(' + arrowDown + ')'
        } else {
          style['background-image'] = 'url(' + arrowUp + ')'
        }
      }
      return style
    },
    hasChildren (item) {
      return has(item, 'children')
    },
    isSelRoot (id) {
      return this.selRoot === id
    },
    isRootActive (id) {
      if (this.selected === id) {
        return 'root-active'
      }
      return ''
    },
    isChildActive (id) {
      if (this.selChild === id) {
        return 'child-active'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-container {
  border-radius: 5px;
  overflow: hidden;
  .nav-item {
    font-weight: 500;
    color: #4F4F4F;
    text-align: left;
  }
  .title {
    background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%);
    color: #FFFFFF;
  }
  .list-items {
    .root-item {
      cursor: pointer;
      border-bottom: 1px solid #E0E0E0;
    }
    &:last-child{
      .root-item {border-bottom: 0;}
    }
    .child-item {
      font-weight: 500;
      color: #4F4F4F;
      cursor: pointer;
      text-align: left;
    }
    .root-active {
      color: #005FDE;
    }
    .child-active {
      color: #266ce8;
    }
  }
}
@media (max-width: 575.98px) {
  .nav-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;
    .nav-item {
      font-size: 18px;
      padding: 15px;
    }
    .title {
      width: 100%;
      font-size: 21px;
      border: 0;
      color: #FFFFFF;
      background: #56CCF2;
      text-align: center;
      padding: 18px 0;
    }
    .list-items {
      margin: 0 5px;
      .root-item {
        border-bottom: 3px solid transparent!important;
      }
      .root-active {
        border-bottom: 3px solid #005FDE!important;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .nav-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;
    .nav-item {
      font-size: 18px;
      padding: 15px;
    }
    .title {
      width: 100%;
      font-size: 21px;
      border: 0;
      color: #FFFFFF;
      background: #56CCF2;
      text-align: center;
      padding: 18px 0;
    }
    .list-items {
      margin: 0 5px;
      .root-item {
        border-bottom: 3px solid transparent!important;
      }
      .root-active {
        border-bottom: 3px solid #005FDE!important;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .nav-container {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    .nav-item {
      font-size: 17px;
      padding: 13px 22px;
    }
    .title {
      font-size: 19px;
    }
    .list-items {
      .child-item {
        font-size: 16px;
        padding: 13px 22px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-container {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    .nav-item {
      font-size: 20px;
      padding: 15px 30px;
    }
    .title {
      font-size: 21px;
    }
    .list-items {
      .child-item {
        font-size: 16px;
        padding: 15px 40px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .nav-container {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    .nav-item {
      font-size: 20px;
      padding: 15px 30px;
    }
    .title {
      font-size: 21px;
    }
    .list-items {
      .child-item {
        font-size: 16px;
        padding: 15px 40px;
      }
    }
  }
}
</style>
