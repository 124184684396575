<template>
  <div class="faq-container">
    <div class="head-container">
      <h2>常见问题</h2>
    </div>
    <div class="content-container">
      <question-item
        v-for="(item, idx) in FAQ"
        :key="'question-' + idx"
        :questionIdx="idx"
        :question="item" />
    </div>
  </div>
</template>

<script>
import QuestionItem from './QuestionItem'
import { getFaqs } from '@/api/client/faqs'

export default {
  components: {
    QuestionItem
  },
  data () {
    return {
      FAQ: []
    }
  },
  created () {
    getFaqs()
      .then(res => {
        this.FAQ = res.data
      })
      .catch(err => { console.log(err) })
  }
}
</script>

<style lang="scss" scoped>
.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .head-container {
    text-align: left;
    h2 {
      font-weight: 500;
    }
  }
}
@media (max-width: 575.98px) {
  .faq-container {
    width: 100%;
    .head-container {display: none;}
    .content-container{
      padding: 15px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .faq-container {
    width: 100%;
    .head-container {display: none;}
    .content-container{
      padding: 15px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .faq-container {
    // gap: 20px;
    .head-container {
      margin-bottom: 15px;
      h2 {
        font-size: 26px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .faq-container {
    // gap: 20px;
    .head-container {
      margin-bottom: 20px;
      h2 {
        font-size: 28px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .faq-container {
    // gap: 20px;
    .head-container {
      margin-bottom: 20px;
      h2 {
        font-size: 28px;
      }
    }
  }
}
</style>
