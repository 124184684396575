<template>
  <b-card
    no-body
    class="question-container">
    <b-card-header
      header-tag="header"
      class="head-container"
      role="tab">
      <h3 v-b-toggle="toggleId">
        {{ question.question }}
        <div class="tab-icon tab-collapsed"><b-icon icon="plus" scale="1.8"></b-icon></div>
        <div class="tab-icon tab-show"><b-icon icon="dash" scale="1.8"></b-icon></div>
      </h3>
      <!-- <b-button block v-b-toggle.accordion-1 variant="info">Accordion 1</b-button> -->
    </b-card-header>
    <b-collapse
      :id="toggleId"
      :visible="isFirst"
      accordion="my-accordion"
      role="tabpanel">
      <b-card-body class="content-container">
        <b-card-text v-html="question.content"></b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    questionIdx: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  computed: {
    isFirst: {
      get () {
        return this.questionIdx === 0
      }
    },
    toggleId: {
      get () {
        return 'accordion-' + this.questionIdx
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question-container {
  width: 100%;
  text-align: left;
  border: none;
  margin-bottom: 5px;
  .head-container {
    padding: 0;
    border: none;
    border-radius: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    h3 {
      background: #FFFFFF;
      color: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #4F4F4F;
      padding: 20px 25px;
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      .tab-icon {
        float: right;
        color: #696969;
      }
    }
    .collapsed {
      .tab-collapsed {display: block;}
      .tab-show {display: none;}
    }
    .not-collapsed {
      border-radius: 5px 5px 0 0;
      .tab-collapsed {display: none;}
      .tab-show {display: block;}
    }
  }
  .content-container {
    border: 1px solid #E0E0E0;
    background: #FAFAFA;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    .card-text {font-size: 15px;}
  }
}
@media (max-width: 575.98px) {
  .content-container {padding: 20px;}
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .content-container {padding: 20px;}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .question-container {
    .head-container {
      h3 {
        padding: 15px 20px;
      }
    }
    .content-container {
      padding: 20px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .question-container {
    .content-container {
      padding: 25px;
    }
  }
}
@media (min-width: 1200px) {
  .question-container {
    .content-container {
      padding: 25px;
    }
  }
}
</style>
<style lang="scss">
.question-container{
  .content-container{
    p {
      img {max-width: 100%;}
    }
  }
}
</style>
