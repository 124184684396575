<template>
  <div class="contact-container">
    <div class="contact-intro">
      <h2>联系我们</h2>
      <p v-if="isMemberLogin"> 亲爱的用户您好～<br />请选择联络类别进行问题回报！ </p>
      <p v-else>
        亲爱的用户您好～<br />请先登录会员，并选择联络类别进行问题回报，如未注册过会员还请选择免费加入会员。
      </p>
    </div>
    <div class="contact-infos">
      <!-- SKYPE -->
      <!-- <div class="contact-item">
        <div class="icon">
          <div class="img-container">
            <img :src="Skype" />
          </div>
          <h4>SKYPE</h4>
        </div>
        <div class="contact">live:manycai</div>
      </div> -->
      <!-- Email -->
      <!-- <div class="contact-item">
        <div class="icon">
          <div class="img-container">
            <img :src="Email" />
          </div>
          <h4>E-mail</h4>
        </div>
        <div class="contact">service@manycai.club</div>
      </div> -->
      <div v-if="isMemberLogin" class="contact-form-container">
        <div class="form-title">
          给我们发邮件
          <span class="form-desc">我们会在第一时间将尽快协助处理</span>
        </div>
        <el-form
          :ref="formName"
          :model="query"
          :rules="queryRules"
          label-width="100px"
          label-position="left"
          class="contact-form"
        >
          <el-form-item label="联络类别" align="left" class="form-item" prop="select_list_id">
            <el-select class="form-select" v-model="query.select_list_id" placeholder="请选择">
              <el-option
                v-for="item in contactList"
                :key="item.id"
                :label="item.select_item"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名" class="form-item">
            <el-input class="form-input" v-model="userData.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="昵称" class="form-item">
            <el-input class="form-input" v-model="userData.nickname" disabled></el-input>
          </el-form-item>
          <el-form-item
            label-width="100%"
            class="form-item textarea-row"
            label="请填写您对多彩网的意见和建议"
            prop="content"
          >
            <el-input class="form-textarea" type="textarea" v-model="query.content"></el-input>
          </el-form-item>
        </el-form>
        <div class="form-submit-container">
          <el-button class="submit-btn" type="primary" @click="handleSubmit"> 送出 </el-button>
        </div>
      </div>
      <div v-else class="contact-btn-container">
        <div class="btn-container">
          <button @click="handleSignIn">请先登录会员</button>
          <button @click="handleSignUp">免费加入会员</button>
        </div>
      </div>
      <!-- Customer Service -->
      <!-- <div class="contact-item">
        <div class="icon">
          <div class="img-container">
            <img :src="CustomerService" />
          </div>
          <h4 style="font-size:16px;">客服咨询</h4>
        </div>
        <div class="contact">
          <button
            @click="handleCustomerService"
            class="btn-customer-service">
            点我与客服交谈
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Skype from '@/assets/helpCenter/skype.png'
import Email from '@/assets/helpCenter/email.png'
import CustomerService from '@/assets/helpCenter/customerService.png'
import { goWebsite } from '@/utils/route'
import { isMemberLogin } from '@/utils/member'
import { getSelectList, postContact } from '@/api/client/form'
import resStatus from '@/constants/resStatus'
import { getMe } from '@/api/client/member'
import Account from '@/views/Layout/Account'
import Loading from '@/components/Loading'
import { isMobile } from '@/utils/device'

export default {
  components: {
    Account,
    Loading,
  },
  props: {
    login: {
      type: Object,
    },
  },
  data() {
    return {
      formName: 'form',
      showLoad: false,
      Skype,
      Email,
      CustomerService,
      contactList: [],
      userData: [],
      query: {
        select_list_id: '',
        content: '',
      },
      queryRules: {
        select_list_id: [
          {
            required: true,
            message: '请选择联络类别',
            trigger: ['blur', 'change'],
          },
        ],
        content: [
          {
            required: true,
            message: '请填写您对多彩网的意见和建议',
            trigger: ['blur', 'change'],
          },
        ],
      },
      isMemberLogin: false,
    }
  },
  methods: {
    handleSubmit() {
      this.$refs[this.formName].validate((validate) => {
        if (validate) {
          this.postContactData()
          this.$refs[this.formName].resetFields()
        }
      })
    },
    postContactData() {
      postContact(this.query)
        .then((res) => {
          switch (res.status) {
            case resStatus.OK:
              this.$message('送出成功')
              this.query.select_list_id = ''
              this.query.content = ''
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    handleSignUp() {
      this.$emit('updateLogin', {
        formType: 'SignUp',
      })
    },
    handleSignIn() {
      this.$emit('updateLogin', {
        formType: 'SignIn',
      })
    },
    handleLoading(boo) {
      this.showLoad = boo
    },
    handleCustomerService() {
      const serviceUrl =
        'https://vue.livelyhelp.chat/chatWindow.aspx?siteId=5000757&planId=645&chatGroup=1'
      goWebsite(serviceUrl, '_blank')
    },
    getContactSelectList() {
      if (isMemberLogin()) {
        this.isMemberLogin = true
        getMe()
          .then((res) => {
            switch (res.status) {
              case resStatus.OK:
                this.userData = res.data
                this.$store.commit('member/setCategory', res.data.category)
                break
              default:
                console.log(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.isMemberLogin = false
      }
      getSelectList(this.query).then((res) => {
        if (res.status === resStatus.OK) {
          this.contactList = res.data.contact
        }
      })
    },
  },
  created() {
    this.getContactSelectList()
  },
  watch: {
    login(cur, prev) {
      this.isMemberLogin = cur.isLogin
      if (cur.isLogin) {
        this.getContactSelectList()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .contact-intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #4f4f4f;
    text-align: left;
    h2 {
      font-weight: 500;
    }
  }
  .contact-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contact-item {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .icon {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        h4 {
          color: #005fde;
        }
      }
      .contact {
        color: #0d184b;
        button {
          background: linear-gradient(234.8deg, #ff9356 7.81%, #ee4a72 73.1%);
          box-shadow: 0px 2px 8px rgba(235, 87, 87, 0.25);
          color: #ffffff;
          border: none;
        }
      }
    }
    .contact-btn-container {
      -webkit-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      .btn-container {
        button {
          display: block;
          width: fit-content;
          width: 172px;
          padding: 0 30px;
          font-weight: 500;
          font-size: 18px;
          border: none;
          box-sizing: border-box;
          margin: 10px auto 20px;
        }
        :first-child {
          color: #ffffff;
          background: linear-gradient(77.4deg, #0053ff 3.38%, #579fff 91.23%);
          border-radius: 5px;
        }
        :not(:first-child) {
          color: #2f80ed;
          background: #ffffff;
          border: 2px solid #2f84ed;
          border-radius: 5px;
          margin-top: 15px;
        }
      }
    }
    .contact-form-container {
      width: 100%;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
      text-align: left;
      .form-title {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 25px 10px;
        border-bottom: 1px solid #e0e0e0;
        .form-desc {
          font-size: 13px;
          padding: 0 25px 15px;
          color: #747474;
          font-weight: 400;
        }
      }
      .contact-form {
        padding: 20px 0 0;
        .form-item {
          padding: 0 25px;
        }
      }
      .form-submit-container {
        text-align: center;
        margin: 0 25px 25px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        .submit-btn {
          padding: 15px 40px;
          background: linear-gradient(234.8deg, #ff9356 7.81%, #ee4a72 73.1%);
          box-shadow: 0px 2px 8px rgba(235, 87, 87, 0.25);
          border: none;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .contact-container {
    padding: 15px;
    // gap: 70px;
    .contact-intro {
      margin-bottom: 10px;
      // gap: 20px;
      h2 {
        display: none;
      }
      p {
        font-size: 15px;
        line-height: 28px;
      }
    }
    .contact-infos {
      // gap: 25px;
      .contact-item {
        margin-bottom: 25px;
        // gap: 30px;
        .icon {
          width: 125px;
          .img-container {
            width: 40px;
            text-align: left;
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
          }
        }
        .contact {
          font-weight: 500;
          font-size: 18px;
          button {
            font-weight: 500;
            font-size: 16px;
            padding: 7px 21px;
            border-radius: 100px;
          }
        }
      }
      .contact-btn-container {
        margin-top: 40px;
        .btn-container {
          button {
            width: fit-content;
            min-width: 6em;
            padding: 16px 30px;
            font-size: 16px;
            border-radius: 5px;
            &:first-child {
              min-width: calc(6em + 4px);
            }
            &:last-child {
              padding: 14px 30px;
            }
          }
        }
      }
      .contact-form-container {
        margin: 20px 0 0;
        box-shadow: none;
        .form-title {
          font-size: 20px;
          padding: 20px 0 10px;
          border-bottom: none;
          border-top: 1px solid #e0e0e0;
          .form-desc {
            display: block;
            font-size: 14px;
            padding: 5px 0 0;
          }
        }
        .contact-form {
          padding: 20px 0 0;
          .form-item {
            padding: 0;
            .form-input {
              width: 100%;
            }
            .form-textarea {
              width: 100%;
            }
          }
        }
        .form-submit-container {
          margin: 35px 0 13px;
          .submit-btn {
            width: 100%;
            padding: 14px 0;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-container {
    padding: 15px;
    // gap: 70px;
    .contact-intro {
      margin-bottom: 10px;
      // gap: 20px;
      h2 {
        display: none;
      }
      p {
        font-size: 15px;
        line-height: 28px;
      }
    }
    .contact-infos {
      // gap: 25px;
      .contact-item {
        margin-bottom: 25px;
        // gap: 30px;
        .icon {
          width: 125px;
          .img-container {
            width: 40px;
            text-align: left;
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
          }
        }
        .contact {
          font-weight: 500;
          font-size: 18px;
          button {
            font-weight: 500;
            font-size: 16px;
            padding: 7px 21px;
            border-radius: 100px;
          }
        }
      }
      .contact-btn-container {
        margin-top: 40px;
        .btn-container {
          button {
            width: fit-content;
            min-width: 6em;
            padding: 15px 30px;
            font-size: 16px;
            border-radius: 5px;
            &:first-child {
              min-width: calc(6em + 4px);
            }
            &:last-child {
              padding: 13px 30px;
            }
          }
        }
      }
      .contact-form-container {
        margin: 20px 0 0;
        box-shadow: none;
        .form-title {
          font-size: 20px;
          padding: 20px 0 10px;
          border-bottom: none;
          border-top: 1px solid #e0e0e0;
          .form-desc {
            display: block;
            font-size: 14px;
            padding: 5px 0 0;
          }
        }
        .contact-form {
          padding: 20px 0 0;
          .form-item {
            padding: 0;
            .form-input {
              width: 100%;
            }
            .form-textarea {
              width: 100%;
            }
          }
        }
        .form-submit-container {
          margin: 35px 0 13px;
          .submit-btn {
            width: 100%;
            padding: 14px 0;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-container {
    // gap: 70px;
    .contact-intro {
      margin-bottom: 20px;
      // gap: 20px;
      h2 {
        font-size: 26px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        line-height: 32px;
      }
    }
    .contact-infos {
      // gap: 25px;
      .contact-item {
        margin-bottom: 25px;
        // gap: 30px;
        .icon {
          width: 125px;
          .img-container {
            width: 40px;
            text-align: left;
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .contact-btn-container {
        margin-top: 40px;
        .btn-container {
          button {
            width: fit-content;
            min-width: 6em;
            padding: 16px 30px;
            font-size: 16px;
            border-radius: 5px;
            &:first-child {
              min-width: calc(6em + 4px);
            }
            &:last-child {
              padding: 13px 30px;
            }
          }
        }
      }
      .contact-form-container {
        margin-top: 20px;
        .form-title {
          font-size: 18px;
          padding: 15px 20px 10px;
          .form-desc {
            font-size: 13px;
            padding: 0 20px 15px;
          }
        }
        .contact-form {
          padding: 20px 0 0;
          .form-item {
            padding: 0 20px;
            .form-input {
              width: 90%;
            }
            .form-textarea {
              width: 100%;
            }
          }
        }
        .form-submit-container {
          margin: 0 25px 25px;
          .submit-btn {
            padding: 15px 40px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact-container {
    // gap: 70px;
    .contact-intro {
      margin-bottom: 20px;
      // gap: 20px;
      h2 {
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 44px;
      }
    }
    .contact-infos {
      // gap: 25px;
      .contact-item {
        margin-bottom: 25px;
        // gap: 30px;
        .icon {
          width: 125px;
          .img-container {
            width: 40px;
            text-align: left;
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .contact-btn-container {
        margin-top: 50px;
        .btn-container {
          button {
            width: fit-content;
            min-width: 6em;
            padding: 16px 30px;
            font-size: 18px;
            border-radius: 5px;
            &:first-child {
              min-width: calc(6em + 4px);
            }
            &:last-child {
              padding: 13px 30px;
            }
          }
        }
      }
      .contact-form-container {
        margin-top: 20px;
        .form-title {
          font-size: 20px;
          padding: 15px 25px 10px;
          .form-desc {
            font-size: 13px;
            padding: 0 25px 15px;
          }
        }
        .contact-form {
          padding: 20px 0 0;
          .form-item {
            padding: 0 25px;
            .form-input {
              width: 80%;
            }
            .form-textarea {
              width: 100%;
            }
          }
        }
        .form-submit-container {
          margin: 0 25px 25px;
          .submit-btn {
            padding: 15px 40px;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .contact-container {
    // gap: 70px;
    .contact-intro {
      margin-bottom: 20px;
      // gap: 20px;
      h2 {
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 44px;
      }
    }
    .contact-infos {
      // gap: 25px;
      .contact-item {
        margin-bottom: 25px;
        // gap: 30px;
        .icon {
          width: 125px;
          .img-container {
            width: 40px;
            text-align: left;
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .contact-btn-container {
        margin-top: 50px;
        .btn-container {
          button {
            width: fit-content;
            min-width: 6em;
            padding: 16px 30px;
            font-size: 18px;
            border-radius: 5px;
            &:first-child {
              min-width: calc(6em + 4px);
            }
            &:last-child {
              padding: 13px 30px;
            }
          }
        }
      }
      .contact-form-container {
        width: 86%;
        margin-top: 20px;
        .form-title {
          font-size: 20px;
          padding: 15px 25px 10px;
          .form-desc {
            font-size: 13px;
            padding: 0 25px 15px;
          }
        }
        .contact-form {
          padding: 20px 0 0;
          .form-item {
            padding: 0 25px;
            .form-input {
              width: 70%;
            }
            .form-textarea {
              width: 100%;
            }
          }
        }
        .form-submit-container {
          margin: 0 25px 25px;
          .submit-btn {
            padding: 15px 40px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@media (max-width: 575.98px) {
  .contact-form {
    .form-item {
      margin-bottom: 15px;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-form {
    .form-item {
      margin-bottom: 15px;
    }
  }
}
.contact-form {
  .textarea-row {
    label {
      text-align: left;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  label {
    padding: 0 !important;
  }
}
</style>
