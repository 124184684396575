export const helpCenterList = [
  // {
  //   id: 'Account',
  //   content: '帐户相关'
  //   // children: [
  //   //   {
  //   //     id: 'RegisterHow',
  //   //     content: '如何注册多彩网会员?'
  //   //   },
  //   //   {
  //   //     id: 'RegisterNote',
  //   //     content: '注册多彩网会员注意事项'
  //   //   }
  //   // ]
  // },
  {
    id: 'FAQ',
    content: '常见问题'
  },
  {
    id: 'Contact',
    content: '联系我们'
  }
]
