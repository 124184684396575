<template>
  <div class="help-center-container">
    <div class="nav-container">
      <help-center-nav
        :selected="selComponent"
        @handleChangeComponent="handleChangeComponent" />
    </div>
    <div class="content-container">
      <keep-alive>
        <component
          :is="selComponent"
          :login="login"
          @updateLogin="updateLogin" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import HelpCenterNav from './HelpCenterNav'
import Account from './account'
import Contact from './Contact'
import FAQ from './FAQ'
import RegisterHow from './account/RegisterHow'
import RegisterNote from './account/RegisterNote'

export default {
  components: {
    HelpCenterNav,
    Account,
    Contact,
    FAQ,
    RegisterHow,
    RegisterNote
  },
  props: {
    login: {
      type: Object
    }
  },
  data () {
    return {
      selComponent: 'FAQ'
    }
  },
  created () {
    let query = this.$route.query
    if (query.id) {
      this.selComponent = query.id
    } else {
      this.$router.replace({query: {id: this.selComponent}})
    }
  },
  methods: {
    handleChangeComponent (id) {
      this.selComponent = id
      this.$router.replace({query: {id: id}})
    },
    updateLogin (obj) {
      this.$emit('updateLogin', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.help-center-container {
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 5px;
  .nav-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .content-container {
    flex-grow: 99;
  }
}
@media (max-width: 575.98px) {
  .help-center-container {
    width: 100%;
    min-height: calc(100vh - 52px - 100px);
    flex-direction: column;
    border-radius: 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .help-center-container {
    width: 100%;
    min-height: calc(100vh - 52px - 100px);
    flex-direction: column;
    border-radius: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .help-center-container {
    width: 98%;
    margin: 20px 0;
    padding: 30px 20px;
    // gap: 70px;
    .nav-container {
      width: 180px;
      margin-right: 30px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .help-center-container {
    width: 98%;
    margin: 20px 0 30px;
    padding: 30px 30px 40px;
    // gap: 70px;
    .nav-container {
      width: 250px;
      margin-right: 60px;
    }
  }
}
@media (min-width: 1200px) {
  .help-center-container {
    margin: 20px 0 30px;
    padding: 40px 50px 55px;
    // gap: 70px;
    .nav-container {
      width: 290px;
      margin-right: 70px;
    }
  }
}
</style>
