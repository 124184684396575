import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

export function getSelectList () {
  return request({
    url: '/api/form/select_list',
    method: 'get'
  })
}

export function postFeedBack (data) {
  return request({
    url: 'api/form/feedback',
    method: 'post',
    data: getFormData(data)
  })
}

export function postContact (data) {
  return request({
    url: 'api/form/contact',
    method: 'post',
    data: getFormData(data)
  })
}